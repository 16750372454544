import './Footer.css';



const Footer = () =>{
    return(
        <div className='footer_Container'>
           <p> ©Copyright 2022 New York Dragon Peace Enterprise Inc. All rights reserved</p>
        </div>
    )

}


export default Footer;